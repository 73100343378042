// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import './vendor/hyphenator';
require('./vendor/infobox');
import house_accordion from './house_accordion';
import base_slider from './base_slider';
import events_slider from './events_slider';
// import cursor from './cursor';
import {
    show_hide_more
} from './event_list';
import init_order_form from './order';
import top_order_form from './order/top_order_form';
import header_form_events from './header_form_events';
import sandwich from './sandwich';
import accordion from './accordion';
import main_tabs from './main_tabs';
import links from './links';
import subscribe from './subscribe';
// import lineliner from './lineliner';

import news_gallery from './news_gallery';
import main_news from './main_news';

// Calendar filter
import filter_datepicker from './filter_datepicker';

document.addEventListener("turbolinks:load", function() {
    house_accordion();
    base_slider();
    events_slider();
    // cursor();
    show_hide_more();
    init_order_form();
    top_order_form();
    header_form_events();
    sandwich();
    accordion();
    main_tabs();
    links();
    subscribe();
    news_gallery();
    main_news();
    // lineliner();

    // Calendar filter
    filter_datepicker();
});
