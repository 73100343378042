import Vue from 'vue/dist/vue.esm';

function main_tabs() {
    const root_id = 'main_sponsors';
    if (!document.getElementById(root_id)) {
        return;
    }

    const tablist =  new Vue({
        el: `#${root_id}`,
        data: function() {
            return {
                screen: 'sponsors',
            };
        },
        methods: {
            goto: function(slide) {
                this.screen = slide;
            },
        },
    });
}

export default main_tabs;
