
export default function sandwich() {
    const btn_open = document.querySelector('.header-sandwich-btn');
    const btn_close = document.querySelector('.sandwich-close-btn');
    const CSS_SELECTOR = 'show-sandwich';
    let current;

    btn_open.addEventListener('click', function(){
        document.body.classList.add(CSS_SELECTOR);
    });

    btn_close.addEventListener('click', function(){
        document.body.classList.remove(CSS_SELECTOR);
    });

    document.querySelectorAll('.sandwich-li-link').forEach((node) => {
        node.addEventListener('click', function(){
            const parent = this.parentNode;
            if (current && (current == parent) && parent.classList.contains('sandwich-li_active')) {
                parent.classList.remove('sandwich-li_active');
                return;
            }
            if (current) {
                current.classList.remove('sandwich-li_active');
            }
            parent.classList.add('sandwich-li_active');
            current = parent;
        });
    });
}
