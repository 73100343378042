import Vue from 'vue/dist/vue.esm';

const TicketCounter = Vue.component('ticket-counter', {
    props: ['value', 'label', 'store'],
    data: function () {
        return {
            count: this.value,
            // sharedStore: this.store,
            // sharedStore: global.store,
        }
    },
    template: `
    <div class="order-tickets-line">
        <div class="order-tickets-left">
            <div class="order-tickets-label">{{label}}</div>
        </div>
        <div class="order-tickets-right">
            <div class="tickets-control">
            <button class="tickets-control-btn" v-on:click="decrease"
                    :disabled="count == 0"
                >—</button>
            <input v-bind:value="count"
                    class="tickets-control-input" readonly />
            <button class="tickets-control-btn" v-on:click="increase"
                    :disabled="store.tickets.total == 6"
                >+</button>
            </div>
        </div>
    </div>
    `,
    methods: {
        increase: function() {
            this.count += 1;
            this.store.increaseTickets();
            this.$emit('input', this.count);
        },
        decrease: function() {
            if (this.count > 0) {
                this.count -= 1;
                this.store.decreaseTickets();
                this.$emit('input', this.count);
            }
        },
    }

});

export default TicketCounter;
