
function reset_zIndex() {
    const element = this.closest('.event-item');
    if (element) {
        element.removeAttribute('style');
    }
    this.removeEventListener('transitionend', reset_zIndex);
}


function show_hide_more () {
    const show_btns = document.querySelectorAll('.event-btn[data-type="show-more"]');
    const hide_btns = document.querySelectorAll('.event-text-return .return-btn');

    show_btns.forEach((node) => {
        node.addEventListener('click', function() {
            const parent = this.closest('.event-item');
            parent.style.zIndex = '3';
            parent.classList.add('event-item_active');
        });
    });

    hide_btns.forEach((node) => {
        node.addEventListener('click', function() {
            const parent = this.closest('.event-item');
            const data_elems = parent.querySelectorAll('.event-text-data');
            parent.classList.remove('event-item_active');
            data_elems.forEach((x) => x.addEventListener('transitionend', reset_zIndex));
        });
    });
}

export { show_hide_more };
