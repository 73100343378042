import axios from 'axios';

function subscribe() {
    let form = document.querySelector('.subscribe-form');
    if (!form) {
        return;
    }

    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    form.addEventListener('submit', (event) => {
        event.preventDefault();
        if (/\S+@\S+\.\S+/.test(form.email.value)) {
            const data = new FormData(form);
            axios.post(form.action, data).then((response) => {
                document.querySelector('.subscribe-after-form').style.display = 'block';
            }).catch((error) => {
                page_unwait(form);
                console.error(error);
            });
        }
    });

}

export default subscribe;
