
export default function accordion() {
    document.querySelectorAll('.accordion-title').forEach((node) => {
        node.addEventListener('click', function() {
            let parent = this.closest('.accordion-item');
            if (parent.classList.contains('accordion-item_active')) {
                parent.classList.remove('accordion-item_active');
            } else {
                parent.classList.add('accordion-item_active');
            }
        });
    });
}
