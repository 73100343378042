function check_link(event) {
    let href = event.currentTarget.getAttribute('href');
    if (href.indexOf('#') >= 0) {
        let target = document.querySelector(`*[data-name="${href.split('#')[1]}"]`);
        if (target) {
            history.replaceState(null, '', href);
            document.body.classList.remove('show-sandwich');
            event.preventDefault();
            let coord_y = target.getBoundingClientRect().y + window.pageYOffset;
            window.scrollTo({
                top: coord_y,
                behavior: 'smooth',
            });
        }
    }
}

function links() {
    // if (window.location.pathname.indexOf('/events/') >= 0) {
    //     let header = document.querySelector('h2');
    //     if (header) {
    //         let parent = header.parentNode;
    //         let coord_y = parent.getBoundingClientRect().y + window.pageYOffset;
    //         window.scrollTo({
    //             top: coord_y,
    //             behavior: 'smooth',
    //         });
    //     }
    // }

    if (window.location.pathname.indexOf('/publications') >= 0) {

        let header = document.querySelector('h2');
        if (header) {
            let parent = header.parentNode;
            let coord_y = parent.getBoundingClientRect().y + window.pageYOffset;
            window.scrollTo({
                top: coord_y,
                behavior: 'smooth',
            });
        }
    }

    if (window.location.pathname.indexOf('/order') >= 0) {
        let header = document.querySelector('h2');
        if (header) {
            let parent = header.parentNode;
            let coord_y = parent.getBoundingClientRect().y + window.pageYOffset;
            window.scrollTo({
                top: coord_y,
                behavior: 'smooth',
            });
        }
    }

    if (window.location.hash.indexOf('#') >= 0) {
        let hash = decodeURI(window.location.hash)
        hash = hash.substring(1, hash.length);
        let target = document.querySelector(`*[data-name="${hash}"]`);
        if (target) {
            history.replaceState(null, '', window.location.href);
            event.preventDefault();
            let coord_y = target.getBoundingClientRect().y + window.pageYOffset;
            window.scrollTo({
                top: coord_y,
                behavior: 'smooth',
            });
        }
    }

    document.querySelectorAll('a').forEach((node) => {
        node.addEventListener('click', check_link);
    });
}

export default links;