
function toggle_scheme() {
    let parent = this.closest('.page-section');
    let wrapper = parent.querySelector('.scheme-wrapper')

    if (this.classList.contains('scheme-btn_active')) {
        this.classList.remove('scheme-btn_active');
        wrapper.style.height = '0px';
    } else {
        this.classList.add('scheme-btn_active');
        let databox = wrapper.querySelector('.two-cols');
        wrapper.style.height = `${databox.offsetHeight}px`;
    }
}


function house_accordion() {
    if (document.querySelector('.history-accordion')) {
        document.querySelectorAll('.history-accordion-header').forEach((node) => {
            node.addEventListener('click', function(){
                let parent = this.parentNode;
                let wrapper = parent.querySelector('.history-accordion-text');

                if (parent.classList.contains('active')) {
                    parent.classList.remove('active');
                    wrapper.style.height = '0px';
                } else {
                    parent.classList.add('active');
                    let databox = wrapper.querySelector('.two-cols');
                    wrapper.style.height = `${databox.offsetHeight}px`;
                }
            });
        });
    }


    let scheme_btn = document.querySelector('.scheme-btn');
    if (scheme_btn) {
        scheme_btn.addEventListener('click', toggle_scheme);
    }

}

export default house_accordion;
