import Swiper from 'swiper/bundle';

function events_slider() {
    const galleryThumbs = new Swiper('.gallery-thumbs', {
        direction: 'vertical',
        slidesPerView: 4,
        spaceBetween: 30,
    });

    const galleryTop = new Swiper('.gallery-top', {
        // loop: true,
        // loopedSlides: 5, //looped slides should be the same
        effect: 'fade',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      });
}

export default events_slider;
