import Swiper from 'swiper/bundle';

const main_news = () => {
    // swiper-news-slider
    const node = document.querySelector('.swiper-news-slider');

    if (!node) {
        return;
    }

    let swiper = new Swiper(node, {
        spaceBetween: 0,
        loop: false,
        // autoHeight: false,
        pagination: {
          el: '.swiper-news-pagination',
          clickable: true,
        },
        navigation: {
            nextEl: '.swiper-news-button-next',
            prevEl: '.swiper-news-button-prev',
        },
        breakpoints: {
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
        }
      });
}

export default main_news;
