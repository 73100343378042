import axios from 'axios';
import moment from 'moment';
import Vue from 'vue/dist/vue.esm';
import VueTheMask from 'vue-the-mask';
import Datepicker from 'vuejs-datepicker';
import {en, uk} from 'vuejs-datepicker/dist/locale'
import Multiselect from 'vue-multiselect'

import TicketCounter from './ticket_component';

import VueI18n from 'vue-i18n';
import { ENGLISH_TRANSLATIONS } from './translations/en';
import { UKRAINIAN_TRANSLATIONS } from './translations/uk';

Vue.use(Datepicker);
Vue.use(VueI18n);
Vue.use(VueTheMask);

const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


const language = document.documentElement.lang;
const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
    uk: UKRAINIAN_TRANSLATIONS,
}

moment.locale(language);
const i18n = new VueI18n({
    locale: language,
    messages: TRANSLATIONS,
});


let store = {
    tickets: {
        total: 0,
    },
    increaseTickets(){
        this.tickets.total += 1;
    },
    decreaseTickets(){
        this.tickets.total -= 1;
    },
};

const select_options = {};

function top_order_form() {
    const root_id = 'top_tickets_block';
    if (!document.getElementById(root_id)) {
        return;
    }

    const re_email = /\S+@\S+\.\S+/;
    const re_text = /[a-zа-яіїґє0-9]{3,}/i;
    const re_tel = /^\+380 \d{2} \d{3} \d{2} \d{2}$/;

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let tickets_data = {};
    let tickets_i18n = {};
    let tickets_price = {};
    window.excursion_tickets.forEach((item) => {
        tickets_data[item.id] = parseInt(item.val, 10);
        tickets_i18n[item.id] = item.title;
        tickets_price[item.id] = parseInt(item.price, 10);
    });

    const screen_list = [
        'screen_select',
        'screen_userdata',
        'screen_confirm',
        'screen_after',
    ];

    const order =  new Vue({
        i18n,
        el: `#${root_id}`,
        components: {
            vuejsDatepicker: Datepicker,
            ticketCounter: TicketCounter,
            Multiselect: Multiselect,
        },
        data: function() {
            return {
                en: en,
                uk, uk,
                screen: screen_list[0],
                order_date: null,
                order_time: null,
                // order_type: null,
                order_type: 'Екскурсія з гідом',
                customer_name: null,
                customer_phone: null,
                customer_email: null,
                tickets_types: tickets_data,
                cal: {
                    disabledDates: {
                        to: yesterday,
                        days: [3],
                    },
                },
                sharedStore: store,
                accept: false,
                order_id: 0,
                tickets_active: false,
            };
        },
        methods: {
            goto: function(slide) {
                this.screen = slide;
            },
            show_tickets_dropdown: function() {
                this.tickets_active = true;
            },
            // validate_user_info: function() {
            //     return this.customer_name != null;
            // },
            send_form: function() {
                let self = this;
                const order_data = {
                    order_date: self.order_date,
                    order_time: self.order_time,
                    order_type: self.order_type,
                    customer_name: self.customer_name,
                    customer_phone: self.customer_phone,
                    customer_email: self.customer_email,
                    tickets_types: self.tickets_types,
                };

                axios.post('/send_order', order_data)
                .then((response) => {
                    const data = response.data;
                    self.order_id = data.order_id;
                    this.screen = 'screen_after';
                }).catch((error) => {
                    console.error(error);
                });
            },
            input_focus: function (event) {
                let parent = event.target.closest('.user-info-line');
                try {
                    parent.classList.add('user-info-line_focused');
                    parent.classList.remove('user-info-line_error');
                } catch(e) {
                    // empty
                }

                if(event.target.type === 'tel' && event.target.value === '') {
                    this.customer_phone = '+380 ';
                    let telinput = event.target;
                    setTimeout(function(){
                        event.target.setSelectionRange(5,5);
                    });
                }
            },
            input_blur: function(event){
                let target = event.target;
                let parent = event.target.closest('.user-info-line');
                let valid = true;
                switch(target.type) {
                    case 'text':
                        valid = re_text.test(target.value);
                        break;
                    case 'tel':
                        valid = re_tel.test(target.value);
                        break;
                    case 'email':
                        valid = re_email.test(target.value);
                        break;
                }

                try {
                    if (!valid) {
                        parent.classList.add('user-info-line_error');
                    }
                    if (target.value === '') {
                        parent.classList.remove('user-info-line_focused');
                    }
                } catch(e) {
                    // empty
                }
            },
            back: function() {
                let target = screen_list.indexOf(this.screen) - 1;
                if (target < 0) {
                    target = 0;
                }
                this.goto(screen_list[target]);
            },
        },
        computed: {
            printDate: function () {
                return moment(this.order_date).format('LL');
            },
            printTickets: function () {
                let output = [];
                for (let [key, value] of Object.entries(this.tickets_types)) {
                    if (value > 0) {
                        output.push(`${tickets_i18n[key]} x ${value}`);
                    }
                }
                output = output.join(', ')
                output = output.charAt(0).toUpperCase() + output.slice(1)
                return output
            },
            validate_slide_one: function () {
                let valid = store.tickets.total > 0;
                valid = valid && this.order_date && this.order_time && this.order_type;
                return !valid;
            },
            validate_slide_two: function() {
                return !(this.customer_phone && re_tel.test(this.customer_phone) &&
                        this.customer_name && re_text.test(this.customer_name) &&
                        this.customer_email && re_email.test(this.customer_email));
            },
            total_price: function () {
                let total = 0
                for (let [key, value] of Object.entries(this.tickets_types)) {
                    if (value > 0) {
                        total += value * tickets_price[key];
                    }
                }
                return total;
            },
            print_price: function () {
                return this.total_price > 0 && this.screen != 'screen_select';
            },
        },

    });

    document.addEventListener('click', (event) => {
        if (!event.target.closest('.form-cell--tickets') && order.tickets_active) {
            order.tickets_active = false;
        }
    });

}

export default top_order_form;
