let container;
let form_donation;
let form_tickets;
let audio_notes;

function checkblock() {
    if (this.dataset.type === 'donation') {
        form_donation.classList.add('form_visible');
        form_tickets.classList.remove('form_visible');
        audio_notes.classList.remove('form_visible');
        container.style.height = `${form_donation.offsetHeight}px`;
    } else if (this.dataset.type === 'tickets') {
        form_tickets.classList.add('form_visible');
        audio_notes.classList.add('form_visible');
        form_donation.classList.remove('form_visible');
        container.style.height = `${form_tickets.offsetHeight + audio_notes.offsetHeight}px`;
    }

    // form_tickets.classList.add('form_visible');
    // form_donation.classList.remove('form_visible');
    // container.style.height = `${form_tickets.offsetHeight}px`;

    setTimeout(function(){
        container.style.overflow = 'visible';
        container.style.height = 'auto';
    }, 500);
}


function header_form_events () {
    container = document.querySelector('.form-container');
    form_donation = document.querySelector('.donation-block');
    form_tickets = document.querySelector('.tickets-block');
    audio_notes = document.querySelector('.audio-notes-line')

    document.querySelectorAll('.header-btn-event').forEach((node) => {
        node.addEventListener('click', checkblock);
    });

    // checkblock();

    document.querySelectorAll('*[data-type-event="hide-top-forms"]').forEach((node) => {
        node.addEventListener('click', function(){
            container.style.overflow = 'hidden';
            container.style.height = '0px';
            setTimeout(function(){
                form_tickets.classList.remove('form_visible');
                form_donation.classList.remove('form_visible');
            }, 500);
        });
    });
}

export default header_form_events;
