import axios from 'axios';

const token = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let LIST_IMAGES;
let SLIDER_IMAGES;

function get_news_images() {
    const self = this;
    let count = parseInt(self.dataset.count, 10);
    let value = self.querySelector('span');


    axios.get(self.dataset.url)
        .then(function(response) {
            const data = response.data;
            let quant = LIST_IMAGES.querySelectorAll('li').length;

            let output = {
                previews: [],
                slides: []
            };

            data.images.forEach((x) => {
                output.previews.push(`<li><span data-target="${quant++}" data-type="news-gallery-link"><img src="${x}" alt="" /></span></li>`);
                output.slides.push(`<div class="swiper-slide"><div class="slide-visual-block"><div class="slide-visual-image"><img src="${x}" alt="" /></div></div></div>`);
            });


            LIST_IMAGES.insertAdjacentHTML('beforeend', output.previews.join(''));
            SLIDER_IMAGES.appendSlide(output.slides);
            SLIDER_IMAGES.update();

            count += data.images.length;
            self.dataset.count = count;
            value.innerText = count;

            if (data.next_url) {
                self.dataset.url = data.next_url;
            } else {
                self.parentNode.remove();
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    // .then(function () {
    //      always executed
    // });
}


function show_popup_gallery(self) {
    if (!SLIDER_IMAGES) {
        return;
    }

    SLIDER_IMAGES.slideTo(parseInt(self.dataset.target, 10), 50);
    document.documentElement.classList.add('news-popup-show');
}

const news_gallery = () => {

    document.addEventListener('click', (event) => {
        const target = event.target.dataset.type === 'news-gallery-link' ? event.target : event.target.closest('span[data-type="news-gallery-link"]');
        if (target) {
            event.preventDefault();
            show_popup_gallery(target);
        }
    });

    const close = document.querySelector('.popup-slider .close-btn');
    if (close) {
        close.addEventListener('click', () => {
            document.documentElement.classList.remove('news-popup-show');
        });
    }

    const load = document.querySelector('.btn_get_images');
    SLIDER_IMAGES = global.sliders.find((x) => {
        return x.el.dataset.type === 'popup';
    });
    if (load) {
        LIST_IMAGES = document.querySelector('.news-section-gallery');

        load.addEventListener('click', get_news_images);
    }
};

export default news_gallery;