import Swiper from 'swiper/bundle';

global.sliders = [];

function base_slider() {

  document.querySelectorAll('.swiper-base-slider').forEach((node) => {
    let swiper = new Swiper(node, {
      // slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 0,
      loop: node.dataset.type !== 'popup',
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 'auto',
          },
      }
    });

    global.sliders.push(swiper);

  });
}

export default base_slider;
