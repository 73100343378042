// import axios from 'axios';
import moment from 'moment';
import Vue from 'vue/dist/vue.esm';
import Datepicker from 'vuejs-datepicker';
import {en, uk} from 'vuejs-datepicker/dist/locale'

let box, root;
const language = document.documentElement.lang;
moment.locale(language);

Vue.use(Datepicker);

const init_user_events = () => {
    document.querySelectorAll('*[data-event-type="show_filter_datepicker"]').forEach((node) => {
        node.addEventListener('click', (event) => {
            event.preventDefault();
            box.classList.add('visible');
        });
    });

    document.addEventListener('mousedown', (event) => {
        if (!event.target.closest('.filter-datepicker-box')) {
            box.classList.remove('visible');
        }
    });

}


const filter_datepicker = () => {
    const root_id = 'filter_datepicker';
    root = document.getElementById(root_id);
    box = document.querySelector('.filter-datepicker-box');

    if (!root || !box) {
        return;
    }

    init_user_events();

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const filter =  new Vue({
        el: `#${root_id}`,
        components: {
            vuejsDatepicker: Datepicker,
        },
        data: function() {
            return {
                filter_date: null,
                uk: uk,
                cal: {
                    disabledDates: {
                        to: yesterday,
                        days: [3],
                    },
                },
            };
        },
        // watch: {
        //     filter_date: function(value) {
        //         this.send_filter_date();
        //     }
        // },
        methods: {
            send_filter_date: function(){
                if (this.filter_date) {
                    window.location = `${window.location.pathname}?date=${moment(this.filter_date).format('DD.MM.YYYY')}`
                    box.classList.remove('visible');
                }
            },
            reset: function(){
                this.filter_date = null;
                box.classList.remove('visible');
            },
        }
    });
}

export default filter_datepicker;